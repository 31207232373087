[tip] {
  position: relative;
}
[tip]:after {
  content: attr(tip);
  font-family: Proxima-Regular, sans-serif;
  background-color: var(--bg-darkblue);
  color: #fff;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: 100%;
  left: 0;
  /* white-space: nowrap; */
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #000;
  border-radius: 0.25rem;
  z-index: 99999;
  visibility: hidden;
}
[tip]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
