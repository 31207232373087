/** START Toastr Overrides **/
.ngx-toastr {
  border-radius: .5rem !important;
}
.toast-info {
  background-color: var(--text-blue-color) !important;
}
.toast-top-center {
  top: 5% !important;
}
/** END Toastr Overrides **/
