.sb-group.sb-modern-dark {
  justify-content: center;
}

.marquee.qs-marquee {
  animation: marquee 15s linear infinite;
}

.questions-short-banner__wrap {
  background-image: url(../images/questions-short-banner.jpg);
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  min-height: 60vh;
  height: auto;
}

.questions-short-banner__wrap h1 > span {
  font-size: 60px;
  line-height: 1.5;
  display: block;
  margin: 40px 0;
  text-shadow: 5px 8px 10px rgba(0, 0, 0, 0.8);
}

.questions-short-banner__wrap .hl-text {
  position: relative;
}

.questions-short-banner__wrap .hl-text::after {
  position: absolute;
  content: "";
  bottom: 4px;
  left: 0;
  height: 4px;
  width: 100%;
  background-color: #fff;
  transform: rotate(355deg);
}

.questions-short-banner__wrap h2 {
  font-size: 32px;
  line-height: 1.2;
}

.questions-short-banner__wrap .hero-box {
  background: #fff;
  padding: 48px;
}

.questions-short-banner__wrap .hero-box p.text-darkblue {
  font-size: 26px;
  line-height: 1.3;
}

.questions-short-banner__wrap .hero-box .text-black {
  font-size: 24px;
  line-height: 1.3;
  margin-top: 6px;
}

.questions-short-banner__wrap .hero-box .bg-primary {
  background-color: #005289 !important;
  margin: 2.5rem 0;
}

.get-organized-banner__wrap {
  background-image: url(../images/get_organized-hero-bg.jpeg);
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  min-height: 80vh;
  height: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
}

.get-organized-banner__wrap::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
}

.get-organized-banner__wrap h1 {
  font-size: 48px;
  line-height: 1.3;
  display: block;
  margin: 0 auto;
  text-shadow: 5px 8px 10px rgba(0, 0, 0, 0.8);
  max-width: 680px;
}

.get-organized-banner__wrap h2 {
  font-size: 32px;
  line-height: 1.2;
}

.get-organized-banner__wrap .hero-box {
  background: #fff;
  padding: 48px;
}

.get-organized-banner__wrap .hero-box p.text-darkblue {
  font-size: 26px;
}

.get-organized-banner__wrap .hero-box .text-black {
  font-size: 24px;
}

.get-organized-banner__wrap .hero-box .bg-primary {
  margin: 2.5rem 0;
}

.section.digital-legacy-ss {
  padding: 5rem 0 6rem;
}

.section.digital-legacy-ss h2 {
  line-height: 1.3;
  font-size: 20px;
  margin-bottom: 12px;
}

.section.digital-legacy-ss p,
.section.digital-legacy-ss ul li {
  font-size: 24px;
  margin-bottom: 15px;
  line-height: 1.3;
}

.section.digital-legacy-ss a {
  color: #e04403;
  text-decoration: underline;
  cursor: pointer;
}

.digital-legacy-lst li {
  padding-left: 40px;
  position: relative;
}

.digital-legacy-lst li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 20px;
  height: 20px;
  background-image: url(../icons/open.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.digital-legacy-lst li.fill::before {
  width: 26px;
  height: 19px;
  background-image: url(../icons/upload.png);
}

.digital-legacy-lst li.encrypted::before {
  width: 25px;
  height: 25px;
  background-image: url(../icons/encrypted.png);
}

.digital-legacy-lst li.cancel::before {
  width: 23px;
  height: 27px;
  background-image: url(../icons/cancel.png);
}

.share-box {
  background-color: #ffece4;
  padding: 40px;
  margin-top: 48px;
  border: 2px solid #e04403;
  position: relative;
}

.share-box h2 {
  font-size: 36px;
}

.share-btn {
  background: #e04403;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  max-width: 300px;
  width: 100%;
  color: #fff;
  font-size: 28px;
}

.share-btn img {
  width: 24px;
  margin-right: 15px;
}

.share-box .share-btn {
  position: absolute;
  bottom: -37px;
  left: calc(50% - 150px);
}

.section.why-choose-legacy-ss {
  padding: 5rem 0;
}

.section.start-now-ss {
  padding: 5rem 0;
  background-image: url(../images/questions-short-start-now-bg.png);
  background-position: bottom right -150px;
  background-size: contain;
  background-repeat: no-repeat;
}

.section.start-now-ss h2 {
  font-size: 42px;
  line-height: 1.3;
}

.start-now-ss p {
  font-size: 24px;
  margin-top: 20px;
  max-width: 390px;
}

.section.start-now-ss .bg-primary {
  background-color: #005289 !important;
  margin: 2.5rem 0 0;
  max-width: 340px;
}

.section.legacy-box-mobile {
  padding: 3rem 0;
}
.legacy-box-mobile .hero-box {
  padding: 40px;
}
.legacy-box-mobile .hero-box .bg-primary {
  margin: 1.5rem 0 !important;
}
.legacy-box-mobile  .hero-box h2 {
  font-size: 20px;
}
.legacy-box-mobile .hero-box p {
  font-size: 18px;
}

@media (max-width: 1359px) {
  .questions-short-banner__wrap h1 > span,
  .get-organized-banner__wrap h1 > span {
    font-size: 52px;
    line-height: 1.3;
  }

  .questions-short-banner__wrap .hero-box,
  .get-organized-banner__wrap .hero-box {
    padding: 48px 40px;
  }

  .questions-short-banner__wrap h2,
  .get-organized-banner__wrap h2 {
    font-size: 27px;
  }

  .questions-short-banner__wrap .hero-box p.text-darkblue,
  .get-organized-banner__wrap .hero-box p.text-darkblue {
    font-size: 23px;
  }

  .questions-short-banner__wrap .hero-box .text-black,
  .get-organized-banner__wrap .hero-box .text-black {
    font-size: 22px;
  }

  .share-box h2 {
    font-size: 32px;
  }
}

@media (max-width: 1280px) {
  .max-w-screen {
    max-width: 100%;
    padding: 0 2rem;
  }

  .get-organized-banner__wrap h1 {
    font-size: 42px;
  }
}

@media (max-width: 1023px) {
  .mob-column-reverse {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 2rem;
    border-bottom: 5px solid #e04403;
  }

  .max-w-screen {
    max-width: 640px;
  }

  .custom-width {
    margin-right: auto !important;
  }

  .questions-short-banner__wrap {
    padding-top: 60px;
  }

  .questions-short-banner__wrap .hero-box,
  .get-organized-banner__wrap .hero-box {
    max-width: 400px;
    margin: 0 auto;
  }

  .questions-short-banner__wrap h1 > span {
    font-size: 44px;
  }

  .get-organized-banner__wrap {
    padding: 60px 0 15rem;
    min-height: auto;
    background-image: url(../images/get_organized-hero-bg-mob.jpeg);
  }

  .get-organized-banner__wrap h1 {
    margin-bottom: 20px;
    font-size: 32px;
  }

  .section.start-now-ss {
    background-size: auto;
  }

  .section.start-now-ss p {
    max-width: 100%;
  }

  .share-box h2 {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .marquee.qs-marquee {
    animation: marquee 10s linear infinite;
  }

  .questions-short-banner__wrap {
    padding: 20px 0 10px;
    min-height: auto;
  }

  .get-organized-banner__wrap {
    padding: 60px 0 12rem;
  }

  .max-w-screen {
    max-width: 480px;
    padding: 0 16px;
  }

  .questions-short-banner__wrap h1 > span {
    font-size: 28px;
    margin: 24px 0;
    text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8);
  }

  .questions-short-banner__wrap .hl-text::after {
    bottom: -2px;
    height: 3px;
  }

  .questions-short-banner__wrap h2,
  .get-organized-banner__wrap h2 {
    font-size: 20px;
  }

}

@media (max-width: 480px) {
  .marquee.qs-marquee {
    animation: marquee 5s linear infinite;
  }

  .section.digital-legacy-ss {
    padding: 2rem 0 4rem;
  }

  .digital-legacy-lst li {
    padding-left: 35px;
  }

  .section.digital-legacy-ss p,
  .section.digital-legacy-ss ul li {
    font-size: 18px;
    line-height: 1.3;
  }

  .share-box {
    padding: 24px 24px 32px;
    margin-top: 32px;
  }

  .share-box .share-btn {
    bottom: -25px;
    left: calc(50% - 100px);
    font-size: 20px;
    padding: 10px;
    max-width: 200px;
  }

  .share-btn img {
    width: 20px;
    margin-right: 12px;
  }

  .section.start-now-ss {
    padding: 3rem 0;
    background-size: contain;
    background-position: bottom right -100px;
  }

  .questions-short-banner__wrap .bg-primary,
  .get-organized-banner__wrap .bg-primary,
  .section.start-now-ss .bg-primary {
    padding: 0.8rem !important;
  }

  .section.start-now-ss h2 {
    font-size: 24px;
  }

  .section.start-now-ss p {
    font-size: 18px;
    margin-top: 15px;
  }
}

@media (max-width: 359px) {
  .section.why-choose-legacy-ss .p-4 {
    padding: 1rem 0;
  }
}
