.can-toggle {
  position: relative;
  cursor: pointer;
}
.can-toggle *, .can-toggle *:before, .can-toggle *:after {
  box-sizing: border-box;
}
.can-toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  pointer-events: none;
}
.can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
  opacity: 0.4;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 0;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  content: attr(data-checked);
}
.can-toggle label {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}
.can-toggle label .can-toggle__label-text {
  -webkit-box-flex: 1;
          flex: 1;
  padding-left: 32px;
}
.can-toggle label .can-toggle__switch {
  position: relative;
}
.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;
  text-transform: uppercase;
  text-align: center;
}
.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;
  text-transform: uppercase;
  text-align: center;
  background: #002b5c;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  color: rgba(119, 119, 119, 0.5);
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch {
  background-color: #fff;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  color: #ffffff;
}
.can-toggle input[type="checkbox"]:hover ~ label {
  color: #6a6a6a;
}
.can-toggle input[type="checkbox"]:checked ~ label:hover {
  color: #55bc49;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background: #ffffff;
    border: 1px solid #cbd5e0;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #fff;
}
.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
 background: #ffffff;
    border: 1px solid #cbd5e0;
}
.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
  color: #fff;
}
.can-toggle label .can-toggle__label-text {
  -webkit-box-flex: 1;
          flex: 1;
}
.can-toggle label .can-toggle__switch {
  -webkit-transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
      background: #ffffff;
    border: 1px solid #cbd5e0;
}
.can-toggle label .can-toggle__switch:before {
  color: #000;
}
.can-toggle label .can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1), -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #ffffff;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(65px, 0, 0);
          transform: translate3d(65px, 0, 0);
}
.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
}
.can-toggle label {
  font-size: 14px;
}
.can-toggle label .can-toggle__switch {
  height: 36px;
  -webkit-box-flex: 0;
  flex: 0 0 130px;
  border-radius: 4px;
}
.can-toggle label .can-toggle__switch:before {
  left: 67px;
  font-size: 12px;
  line-height: 36px;
  width: 67px;
  padding: 0 12px;
  height: 100%;
}
.can-toggle label .can-toggle__switch:after {
  /*top: 2px;*/
  /*left: 2px;*/
  border-radius: 2px;
  width: 65px;
  line-height: 32px;
  font-size: 12px;
  height: 100%;
}
.can-toggle label .can-toggle__switch:hover:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.toggle-block1{
    left: 36%;
}
.toggle-block2{
    left: 21%;
}
.toggle-block3{
    left: 51%;
}
.toggle-block4{
    left: 26%;
}

@media only screen and (min-width: 300px) and (max-width: 700px) {
.toggle-block1{
    left: 55%;
}
.toggle-block2 {
    left: unset;
    right: 8%;
    top: 23%;
}
.toggle-block3 {
    left: unset;
    right: 35%;
    top: 80%;
}
.toggle-block4 {
    left: 55%;
}
  }