* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100% !important;
}

html {
  scroll-behavior: smooth !important;
}

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.banner__bg-video-wrap {
  position: relative;
  width: 100%;
  /* height: 100vh; */
}

/* video {
    min-width: 100%;
    min-height: 100vh;
    z-index: 1;
} */

.security__wrap,
.our-partnership__wrap,
.insight-inbox__wrap,
.digital-assets__wrap,
.wealth-transition-tool__wrap,
.communication-report__wrap,
.api-integration__wrap,
.need_assistance__wrap,
.industry-sector__wrap,
.completed-programming__wrap,
.services-tech__wrap,
.software-contains {
  background-color: var(--bg-box);
}

.slick-slide {
  margin: 12px;
}

.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.slick-list.draggable {
  padding: 0 !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: var(--dark-blue) !important;
}

.slick-dots li button:before {
  font-size: 20px !important;
}

.slick-dots li button:before {
  color: #bfbfbf !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.company-banner__wrap {
  background-image: url(../images/company/company-banner.webp);
  background-position: bottom center;
  background-size: cover;
  color: #ffffff;
  height: fit-content;
}

.pricing-banner__wrap {
  background-image: url(../images/pricing/pricing-banner-v0.1.png);
  background-position: bottom center;
  background-size: cover;
  color: #ffffff;
  height: fit-content;
}

.feature-banner__wrap {
  /* background-color: #000000; */
  background-image: url(../images/features/feature-banner.webp);
  background-position: bottom center;
  background-size: cover;
  color: #ffffff;
  height: fit-content;
}

.contact-banner__wrap {
  /* background-image: linear-gradient(to bottom, #000, rgba(111, 118, 136, 0) 122%), url(../images/contact/contact-banner.webp); */
  background-image: url(../images/contact/contact-banner.webp);
  background-position: bottom center;
  background-size: cover;
  color: #ffffff;
  height: fit-content;
}

.focused-banner__wrap {
  background-image: url(../images/consumer-focused-banner.png);
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  height: fit-content;
}

.consumer-marketing-banner__wrap {
  background-image: url(../images/conumer-marketing-bg.jpg);
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  height: fit-content;
}

.consumer-intro-banner__wrap {
  background-image: url(../images/intro/landing-banner-intro.png);
}

.bg-blue-light{
  background:#f1f3f4;
  padding:3rem 0 !important;
}
.list .li-disc::marker{
  color:#005289;
}


.contact-banner__wrap::before{
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  height: 80%;
  background: linear-gradient(to bottom, rgb(0 0 0 / .4) 0%, #00000000 100%);
}
 .focused-banner__wrap::before, .consumer-marketing-banner__wrap::before {
   content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  height: 100%;
  background: linear-gradient(to left, rgb(0 0 0 / .4) 0%, #00000000 67%)
 }

/* .home-banner__wrap {
  background-image: linear-gradient(to bottom, #000, rgba(111, 118, 136, 0) 122%), url(../images/banner-clip.mp4);
  background-position: bottom center;
  background-size: cover;
  color: #ffffff;
  height: fit-content;
} */

.bg-red-700 {
  background-color: var(--primary) !important;
}

.hover\:outline-red-700:hover {
  outline-color: var(--primary) !important;
}

.button-primary , .button-dark-blue {
  padding: 12px 24px;
}

.button-get-started {
  padding: 8px 16px;
}

.button-download {
  padding: 15px 35px;
}

.button-primary,
.button-get-started,
.button-download {
  color: #fff;
  background: var(--primary);
  border-radius: .5rem;
  border: 0;
  transition: all 0.3s ease-in-out 0s;
  outline: 3px solid transparent;
  cursor: pointer;
}

.button-primary:hover,
.button-download:hover {
  outline-offset: 3px;
  outline: 3px solid #f06d06;
}

.button-get-started:hover{
  outline-offset: 2px;
  outline: 1px solid #f06d06;
  background: var(--primary-lite);
}

.button-dark-blue {
  color: #fff;
  background: var(--dark-blue);
  border-radius: .5rem;
  border: 0;
  transition: all 0.3s ease-in-out 0s;
  outline: 3px solid transparent;
  cursor: pointer;
}

.button-dark-blue:hover {
  outline-offset: 2px;
  outline: 1px solid var(--dark-blue);
  background: #003677 !important;
}

/* .button-primary::before,
.button-download::before {
    content: '';
    border-radius: .5rem;
    border: 3px solid var(--border-primary);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease-in-out 0s;
}

.button-primary:hover,
.button-download:hover,
.button-download:focus,
.button-primary:focus {
    color: #fff;
    transform: translateY(-6px);
}

.button-primary:hover::before,
.button-download:hover::before,
.button-download:focus::before,
.button-primary:focus::before {
    opacity: 1;
}

.button-primary:hover::after,
.button-download:hover::after,
.button-download:focus::after,
.button-primary:focus::after {
    animation: none;
    display: none;
} */

.select-arrow {
  background-image: url(../images/download.svg);
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
}

.hover\:bg-red-500:hover {
  background-color: var(--primary) !important;
}

.max-w-logo {
  width: 18rem;
}

.max-w-logo img {
  width: 16rem;
}
.main-logo{
  width: 16rem;
}

.banner__bg-video {
  border-bottom-left-radius: 6rem;
  border-bottom-right-radius: 6rem;
}

.banner-padding-hfull {
  height: 100vh;
  padding: 0;
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 400px;
}
.new-landing-height {
  height: 82vh;
  padding: 0;
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 400px;
}
.mh-800-height {
  min-height: 500px;
}

.mh-700-height {
  min-height: 700px;
}

.mh-600-height {
  min-height: 600px;
}

.mh-500-height {
  min-height: 500px;
}

.list-lower-roman {
  list-style: lower-roman;
}
.list-lower-alpha {
  list-style: lower-alpha;
}

.ps-timeline-sec {
  position: relative;
  /* background: #fff; */
}

.ps-timeline-sec .container ol.ps-timeline {
  margin: 70px auto;
  padding: 0;
  border-top: 2px dashed #BCCADB;
  list-style: none;
  width: 80%;
}

.ps-timeline-sec .container ol.ps-timeline:before {
  content: "";
  width: 1px;
  height: 40px;
  /* background: #BCCADB; */
  position: absolute;
  top: 70px;
  /* right: 0%; */
  border-left: 2px dashed #BCCADB;
}

.ps-timeline-sec .container ol.ps-timeline:after {
  content: "";
  width: 8px;
  height: 8px;
  background: var(--dark-blue);
  position: absolute;
  top: 55px;
  left: 49.8%;
  border-radius: 100%;
}

.ps-timeline-sec .container ol.ps-timeline li {
  float: left;
  width: 25%;
  padding-top: 30px;
  height: 65vh;
  position: relative;
}

.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top.left:before {
  content: "";
  color: #348e80;
  width: 2px;
  height: 100px;
  /* background: var(--bg-dark-blue); */
  position: absolute;
  top: 0px;
  left: 0%;
  border-left: 2px dashed #BCCADB;
}

.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top.right:before {
  content: "";
  color: #348e80;
  width: 2px;
  height: 100px;
  /* background: var(--bg-dark-blue); */
  position: absolute;
  top: 0px;
  right: 0%;
  border-right: 2px dashed #BCCADB;
}

.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
  content: "";
  color: #348e80;
  width: 8px;
  height: 8px;
  background: var(--dark-blue);
  position: absolute;
  top: 105px;
  left: -1%;
  border-radius: 100%;
}

.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top.right:after {
  content: "";
  color: #348e80;
  width: 8px;
  height: 8px;
  background: var(--dark-blue);
  position: absolute;
  top: 105px;
  left: 98%;
  border-radius: 100%;
}

.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
  content: "";
  color: #348e80;
  width: 2px;
  height: 50px;
  /* background: var(--bg-dark-blue); */
  border-left: 2px dashed #BCCADB;
  position: absolute;
  top: 0%;
  left: 50%;
}

.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot.second--leftline:before {
  content: "";
  color: #348e80;
  width: 2px;
  height: 50px;
  /* background: var(--bg-dark-blue); */
  border-left: 2px dashed #BCCADB;
  position: absolute;
  bottom: -50px;
  left: 35%;
}

.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot.third--rightline:before {
  content: "";
  color: #348e80;
  width: 2px;
  height: 50px;
  /* background: var(--bg-dark-blue); */
  border-left: 2px dashed #BCCADB;
  position: absolute;
  bottom: -50px;
  left: 65%;
}

.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot.second--leftline:after {
  content: "";
  color: #348e80;
  width: 8px;
  height: 8px;
  background: var(--dark-blue);
  position: absolute;
  top: 55px;
  left: 34%;
  border-radius: 100%;
}

.ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot.third--rightline:after {
  content: "";
  color: #348e80;
  width: 8px;
  height: 8px;
  background: var(--dark-blue);
  position: absolute;
  top: 55px;
  left: 64%;
  border-radius: 100%;
}

.ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
  position: absolute;
  bottom: 0;
  margin-bottom: 130px;
  width: 100%;
}

.ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
  display: table;
  margin: 0 auto;
}

.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
  position: absolute;
  margin-top: 60px;
  width: 100%;
}

.ps-timeline-sec .container ol.ps-timeline li .img-handler-bot img {
  display: table;
  margin: 0 auto;
}

.ps-timeline-sec .container ol.ps-timeline li p {
  /* text-align: center; */
  width: 100%;
  margin: 0 auto;
}

.ps-timeline-sec .container ol.ps-timeline li .ps-top {
  position: absolute;
  top: 80px;
  /* margin-bottom: 100px; */
}

.ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__firstbox {
  position: absolute;
  margin-top: 100px;
  left: -8.5rem;
  width: 275px;
}

.ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__secondbox {
  position: absolute;
  margin-top: 50px;
  left: -3rem;
  width: 275px;
}

.ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__thirdbox {
  position: absolute;
  margin-top: 50px;
  right: -3.5rem;
  width: 275px;
}

.ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__fourbox {
  position: absolute;
  left: 7rem;
  width: 275px;
  top: 8rem;
}

.services-tech__wrap {
  position: relative;
}

.filter-white {
  filter: brightness(0) invert(100%);
}

.filter-darkblue {
  filter: brightness(0) saturate(100%) invert(11%) sepia(56%) saturate(3258%) hue-rotate(200deg) brightness(97%) contrast(101%);
}


.font-size-1-5 {
  font-size: 1.5em;
}

.text--red {
  color: #d83c1e;
}

.text-orange-400 {
  color: #FFA500;
}

.item__description {
  grid-column: 1 / -1;
  height: 20px;
  width: auto;
}

.custom-tooltip {
  font-size: 10px !important;
  /* Set your desired font size here */
}



.custom-mobile-header .hidden {
  position: inherit !important;
  width: auto !important;
  height: auto !important;
  left: auto !important;
}

.contact-form .fsSectionHeader {
  display: none !important;
}

.contact-form .fsBody .fsForm {
  border-radius: 20px !important;
}

.contact-form .b-contact-card .fsBody .fsForm {
  margin: 0px auto;
}

.contact-form .fsSubmitButton {
  padding: 15px 30px;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
  border-radius: 10px !important;
  font-size: 20px !important;
  font-weight: 600;
  line-height: 23px;
  color: #ffffff !important;
  background-color: #003d85 !important;
}

.contact-form .fsSubmitButton:hover,
.contact-form .fsSubmitButton:focus {
  color: #ffffff !important;
  background-color: #000 !important;

}

.contact-form .fsRowBody input[type=radio]:before,
.contact-form .fsRowBody input[type=radio]:checked:before {
  width: 20px !important;
  height: 20px !important;
}

.contact-form .fsRowBody input[type=radio] {
  top: 2px !important;
  width: 20px !important;
  height: 20px !important;
}

.reachus-form .fsBody.fsEmbed {
  padding: 0;
}

.reachus-form .fsBody .fsForm {
  margin: 0px auto;
  padding: 0;
}

.move-map .pac-container {
  width: 100% !important;
  position: absolute !important;
  left: 0 !important;
  top: 70px !important;
}

.marquee {
  animation: marquee 35s linear infinite;
}
@keyframes marquee {
  0% {
    -webkit-transform: translateX(0) translateZ(0);
    transform: translateX(0) translateZ(0);
}
100% {
    -webkit-transform: translateX(-100%) translateZ(0);
    transform: translateX(-100%) translateZ(0);
}
}

.height-400{
  height:75vh !important;
  /* min-height:320px !important;
  padding:40px 0 !important; */
  background-position: center center !important;
}

/* .desktop-title{
  font-size:1.9rem !important;
}
.desktop-title b{
  font-size:2.2rem !important;
} */
.bg-blue-light.py-8{
  padding:1.25rem 0 !important;
}
.button-onsite-guide {
  padding: 8px 15px;
  padding-right: 85px !important;
  line-height: 22px;
  text-align: left;
  font-size: 19px;
  background: var(--primary);
}
.button-onsite-guide .avatar-img{
  width: 100px;
  top: -20px;
  right: -20px;
}
.play{
  position:absolute;
  width:18px;
  height:18px;
  border-radius:100%;
  background:rgb(255 255 255 / 23%);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(255 255 255 / 7%);
  left: 39px;
  bottom: 4px;
}
.play img{
  width: 8px;
  height: 8px;
  margin-left: 3px;
}
.custom-btn .button{
  padding:5px 10px  !important;
}
.custom-btn .button span{
  font-size:13px !important;
}
.card .card_icon .svg-inline--fa.fa-w-16 {
  width: 16px !important;
}
