/* You can add global styles to this file, and also import other style files */

@import url("./assets/css/fonts.css");
@import url("./assets/css/custom-landing.css");
@import url("./assets/css/style.css");
@import url("./assets/css/checkbox.css");
@import url("./assets/css/custom-tooltips.css");
@import url("./assets/css/responsive.css");
@import url("./assets/css/consumer-landing.css");
@import url("./assets/css/overrides.css");

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

html, body {
  height: 100% !important;
}

html {
  scroll-behavior: smooth !important;
}

:root {
  /* --text-darkblue-color: #022957; */
  --text-orange-color: #d72c00;
  --text-blue-color: #003d85;
  --text-charcoal-color: #323232;
  --text-grey-color: #676767;
  --text-darkgrey-color: #323232;
  --text-lightgrey-color: #a8a8a8;
  --text-lightblue-color: #d72c00;
  --text-red-color: #f53d3d;
  --text-yellow-color: #ea7c1f;
  --text-blue-status: #2692f7;
  --text-notverified-status: #d81212;
  --text-approved-status: #05b563;
  --text-verified-status: #7b52bd;
  --text-pending-status: #cea00a;
  --border-grey: #cdcdcd;
  --border-outline-grey: #cecece;
  --border-outline-red: #ce3a15;
  --border-outline-darkblue: #002b5c;
  --border-outline-blue: #f57b5c;
  --border-underline-blue: #d72c00;
  --border-outline-card: #dedbdd;
  --border-dropdown-outline: #b9b9b9;
  --border-underline-lightblue: #90b4de;
  --menu-underline-blue: #6a9cd6;
  --bg-body-grey: #f9f9f9;
  --bg-progress-grey: #dedbdd;
  --bg-progress-green: #069b4b;
  --bg-login-grey: #f6f6f6;
  --bg-red: #d72c00;
  --bg-darkblue: #002b5c;
  --bg-blue: #003d85;
  --bg-pale-blue: #005288;
  --bg-focused-footer: #004A96;
  --bg-nav-btn: #ccd5df;
  --border-xs: 3px;
  --border-darkgrey-outline: #323232;
  --border-white-outline: #fff;
  --icon-color: #a8a8a8;
  --placeholder-grey: #a8a8a8;
  --red-alert-color: #ff2121;
  --green-alert-color: #069b4b;
  --green-alert-color-accent: #0a6935;
  --red-alert-outline: #ff2121;
  --navmenu-grey-color: #c3c3c3;
  --breadcrumb-grey: #8a8a8a;
  --text-disable-grey: #b9b9b9;
  --list-active-bg: #e9f0f9;
  --bg-toggle-blue: #f66c49;
  --bg-modal-overlay: #677e9a;
  --bg-loader-heading: #d3d3d3;
  --bg-loader-content: #e7e7e7;
  --bg-loader-button: #c3ccd6;
  --text-nav-hover: #e04403;
  --text-nav-blue: #003d85;
  --text-pale-blue: #005288;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.custom-border-button {
  border: 1px solid var(--border-outline-red) !important;
  color: var(--border-outline-red) !important;
  background-color: white !important;
}

.bg-white.confirm_cancellation {
  border: 1px solid var(--border-outline-red) !important;
  color: var(--border-outline-red) !important;
  background-color: white !important;
}

@font-face {
  font-family: Proxima-Regular;
  src: url(assets/fonts/ProximaNova-Regular.otf);
  font-display: swap;
}

@font-face {
  font-family: Proxima-Thin;
  src: url(assets/fonts/Proxima-Nova-Thin.otf);
  font-display: swap;
}

@font-face {
  font-family: Proxima-Bold;
  src: url(assets/fonts/Proxima-Nova-Bold.otf);
  font-display: swap;
}

@font-face {
  font-family: Proxima-Extrabold;
  src: url(assets/fonts/Proxima-Nova-Extrabold.otf);
  font-display: swap;
}

@font-face {
  font-family: Proxima-Medium;
  src: url(assets/fonts/Proxima-Nova-Medium.ttf);
  font-display: swap;
}

.text-regular {
  font-family: Proxima-Regular, sans-serif;
}

.text-thin {
  font-family: Proxima-Thin, sans-serif;
}

.text-medium {
  font-family: Proxima-Medium, sans-serif;
}

.text-bold {
  font-family: Proxima-Extrabold, sans-serif;
}

.text-semibold {
  font-family: Proxima-Bold, sans-serif;
}

.bg-login-grey {
  background-color: var(--bg-login-grey);
}

.bg-references-grey {
  background-color: #fdfdfd;
}

.text-darkblue {
  color: var(--text-blue-color);
}

.text-charcoal {
  color: var(--text-charcoal-color);
}

.text-grey {
  color: var(--text-grey-color);
}

.tab-border-red {
  border-color: var(--bg-red);
}

.border-blue {
  border-color: var(--border-underline-blue);
}

.bg-darkblue {
  background-color: var(--bg-darkblue) !important;
}

.bg-green {
  background-color: var(--green-alert-color) !important;
}

.bg-blue {
  background-color: var(--bg-blue) !important;
}

.border-login {
  border-bottom: 1px solid var(--border-outline-grey);
}

.border-grey {
  border-color: var(--border-grey);
}

.input {
  border-color: var(--border-grey);
}

.rounded-xs {
  border-radius: var(--border-xs);
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
}

.fas {
  color: var(--icon-color) !important;
}

::placeholder {
  color: var(--placeholder-grey);
}

.error-alert {
  color: var(--red-alert-color);
}

.error-alert-outline {
  border: 1px solid var(--red-alert-outline);
}

.checking-alert {
  color: var(--green-alert-color);
}

.border-darkgrey {
  border: 1px solid var(--border-darkgrey-outline);
}

.text-navmenu-grey {
  color: var(--navmenu-grey-color);
}

.text-nav-blue {
  color: var(--text-nav-blue);
}

.text-nav-blue:hover {
  color: var(--text-nav-hover);
}

.menu-active {
  text-decoration-line: underline;
  text-underline-offset: 10px;
  color: var(--text-orange-color);
  text-decoration-color: var(--text-orange-color);
  text-decoration-thickness: 2px;
  /* border-bottom: 2px solid #d72c00;  */
}

.menu-active:hover {
  /* text-decoration-line: underline;
  text-underline-offset: 10px; */
  color: var(--text-orange-color);
  /* text-decoration-color: var(--text-orange-color);
  text-decoration-thickness: 2px;
  font-weight: bold; */
  /* border-bottom: 2px solid #d72c00; */
}

.bg-nav-btn {
  background-color: var(--bg-red);
  color: #ffffff;
}

/* .mobile-menuitem:hover,
.mobile-menuitem:active {
  background-color: var(--bg-red);
  color: #fff;
} */

.border-card {
  border: 1px solid var(--border-outline-card);
}

.hr-line {
  border-color: var(--border-outline-card);
}

.text-darkgrey {
  color: var(--text-darkgrey-color);
}

.breadcrumb {
  color: var(--breadcrumb-grey);
}

.text-disable-i {
  color: var(--text-disable-grey) !important;
}

.text-disable {
  color: var(--text-disable-grey);
}

.lighten {
  filter: brightness(200%);
}

.border-white {
  border: 2px solid var(--border-white-outline);
}

.form-input-focus:focus {
  border: 1px solid var(--border-outline-blue);
}

/* error validation */

.form-input-error {
  border: 1px solid var(--red-alert-outline) !important;
}

.bg-progress-blue {
  background-color: var(--border-outline-blue);
}

.bg-progress-green {
  background-color: var(--bg-progress-green);
}

.bg-progress-grey,
.bg-avatar {
  background-color: var(--bg-progress-grey);
}

.border-red {
  border: 1px solid var(--border-outline-red);
}

.border-darkblue {
  border: 1px solid var(--border-outline-darkblue);
}

.border-outline-grey {
  border: 1px solid var(--border-outline-grey);
}

/* Newly Added Css */

/* New Class alone added for existing style  */

.dropdown-border,
.border-card-grey {
  border: 1px solid var(--border-dropdown-outline);
}

.text-gray-700 {
  color: var(--text-grey-color) !important;
}

.text-back {
  color: var(--breadcrumb-grey);
}

.placeholder-grey {
  color: var(--placeholder-grey);
}

.select-option:hover {
  background-color: var(--list-active-bg);
}

.select-option.mat-option:hover {
  background-color: var(--list-active-bg);
}

.list-active-bg {
  background-color: var(--list-active-bg);
}

.mid-line {
  border-color: var(--border-outline-card);
}

.bg-disable-toggle-switch {
  background-color: var(--bg-progress-grey);
}

.bg-enable-toggle-switch,
.bg-edit {
  background-color: var(--bg-toggle-blue);
}

.input:focus {
  border: 1px solid var(--border-outline-blue) !important;
}

.text-label-grey {
  color: var(--breadcrumb-grey);
}

.text-lightblue {
  color: var(--text-lightblue-color);
}

.border-lightblue {
  border: 1px solid var(--border-outline-blue);
}

/* Change color if input is not empty */

input:not([value=""]):not(:focus) {
  /* You need to add a placeholder to your fields. For example: <input "placeholder=" "/> */
  color: var(--text-darkgrey-color);
}

.loading-red {
  width: 18px;
  height: 18px;
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 50%;
  border-top-color: var(--primary);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  z-index: 1;
}

.loading-red-free {
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 50%;
  border-top-color: var(--primary);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  z-index: 1;
}

.loading-blue {
  width: 18px;
  height: 18px;
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 50%;
  border-top-color: var(--border-outline-darkblue);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  z-index: 1;
}

.loading-blue-free {
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 50%;
  border-top-color: var(--border-outline-darkblue);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  z-index: 1;
}

.background-color-grey {
  background-color: var(--bg-body-grey);
}


.shadow-red-300 {
  box-shadow: 0 0 4px 2px #767676;
}

.border-lightblue {
  border-color: var(--border-underline-lightblue);
}

.text-red {
  color: var(--text-red-color);
}

.text-orange {
  color: var(--text-orange-color);
}

.text-yellow {
  color: var(--text-yellow-color);
}

.text-blue-status {
  color: var(--text-blue-status);
}

.text-notverified {
  color: var(--text-notverified-status);
}

.text-approved {
  color: var(--text-approved-status);
}

.text-pending {
  color: var(--text-pending-status);
}

.text-verified {
  color: var(--text-verified-status);
}

.ngx-file-drop__drop-zone[_ngcontent-tfo-c109] {
  border-color: var(--border-grey) !important;
}

.text-grey-800 {
  color: var(--text-darkgrey-color) !important;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.bg-loader-heading {
  background-color: var(--bg-loader-heading);
}

.bg-loader-content {
  background-color: var(--bg-loader-content);
}

.bg-loader-btn {
  background-color: var(--bg-loader-button);
}

.bg-loader-btn {
  background-color: var(--bg-loader-button);
}

.w-50 {
  width: 50% !important;
}

.w-70 {
  width: 70% !important;
}

textarea {
  resize: none !important;
}

/* Overview styling */

.bg-card-overview {
  background-color: #fff !important;
}

.price-input {
  text-indent: 1rem;
}

.bs-datepicker-body table td.week span {
  color: var(--text-orange-color) !important;
}

.bs-datepicker-buttons {
  color: white;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-head,
.bs-datepicker-head,
.bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after,
.bs-datepicker-body table td.active-week span:hover,
.btn-success {
  background-color: var(--text-blue-color) !important;
}

/* css for progress bar */

.w-6px {
  width: 6% !important;
}

.w-12px {
  width: 12% !important;
}

.w-18px {
  width: 18% !important;
}

.w-25px {
  width: 25% !important;
}

.w-31px {
  width: 31% !important;
}

.w-37px {
  width: 37% !important;
}

.w-43px {
  width: 43% !important;
}

.w-50px {
  width: 50% !important;
}

.w-56px {
  width: 56% !important;
}

.w-62px {
  width: 62% !important;
}

.w-68px {
  width: 68% !important;
}

.w-75px {
  width: 75% !important;
}

.w-81px {
  width: 81% !important;
}

.w-87px {
  width: 87% !important;
}

.w-93px {
  width: 93% !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.bg-light-grey-transparent {
  background-color: #a5a7aa75;
}

.application-loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.application-loading-box {
  text-align: center;
}

.warning-msg {
  color: var(--border-outline-red);
}

.ngx-pagination .current {
  background: var(--border-outline-red) !important;
}

.green-tick {
  color: var(--green-alert-color);
  font-size: 1.2rem;
}

.red-tick {
  color: var(--red-alert-color);
  font-size: 1.2rem;
}

.ngx-pagination .current {
  background: var(--border-outline-red) !important;
}

.pagination-next {
  font-size: 28px !important;
  bottom: 5px !important;
}

.pagination-previous {
  font-size: 28px !important;
  bottom: 5px !important;
}

.svg-inline--fa.fa-w-14 {
  color: var(--text-blue-color);
  font-size: 1.25rem;
}

.modal-content {
  padding: 0 20px;
}

.modal-content .form-control {
  display: block;
  width: 100%;
  height: 38px;
  padding: 0.5rem 1.1875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border: none;
  border-bottom: 1px solid #ced4da;
  background: no-repeat bottom, 50% calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  transition: background 0s ease-out;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.modal-content .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #0678ff;
  outline: 0;
  border-bottom-color: transparent;
  background-size: 100% 100%, 100% 100%;
  transition-duration: 0.3s;
  box-shadow: none;
  background-image: linear-gradient(0deg, #d72c00 2px, rgba(70, 128, 255, 0) 0),
  linear-gradient(0deg, #ced4da 1px, rgba(206, 212, 218, 0) 0);
}

.minus {
  position: absolute;
  left: 3px;
  width: 7px;
  background: #8f8f8f;
  height: 2px;
  display: block;
  border-radius: 2px;
  line-height: 20px;
  top: 11px;
}

.set-filter input[type="checkbox"] {
  margin-right: 0.25rem;
}

.set-filter ul {
  position: relative;
  padding-left: 30px;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

/* label {
  display: inline-block;
  margin-bottom: 0.5rem;
} */

.float-right {
  float: right !important;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.treeview-container {
  overflow-y: auto;
  padding-right: 0.3rem;
  max-height: 340px !important;
}

.treeview-item {
  white-space: nowrap;
}

.form-check-label {
  margin-bottom: 0;
  font-family: Proxima-Regular, sans-serif;
  font-size: 14px;
}

.treeview-text {
  font-family: Proxima-Regular, sans-serif;
}

.form-check-inline .form-check-input,
.form-check .form-check-input {
  margin-right: 0.3125rem;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.treeview-container .row-item {
  margin-bottom: 0.3rem;
  flex-wrap: nowrap;
}

.form-inline .form-check {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding-left: 0;
}

.float-right.form-check-label {
  position: relative;
  top: 4px;
}

.green-error-text {
  font-size: 0.7rem;
  margin-top: 0;
  color: var(--green-alert-color);
}

.red-error-text {
  font-size: 0.7rem;
  margin-top: 0;
  color: var(--red-alert-color);
}

.green-error-icon {
  font-size: 0.7rem;
  margin-top: 0;
  /* color: var(--green-alert-color); */
}

.red-error-icon {
  font-size: 0.7rem;
  margin-top: 0;
  /* color: var(--red-alert-color); */
}

.table-responsive {
  display: block;
  width: 100% !important;
  outline: none;
  overflow: auto;
}
/*
@media (hover: none) {
  .tooltip {
    display: none;
  }
} */

@media (max-width: 640px) {
  .custom-header-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
  }

  .ngx-file-drop__content {
    flex-direction: column;
    height: 130px !important;
    gap: 0.5rem !important;
  }

  /* .custom-header-logo .whitespace-nowrap {
    padding-left: 20px;
  } */
  /* re-captcha>div,   re-captcha iframe{
    width: 250px!important;
    height: 60px!important;
  }
  re-captcha iframe .rc-anchor-normal .rc-anchor-content {
    height: 60px!important;
    width: 153px!important;
}
re-captcha iframe .rc-anchor-normal .rc-anchor-checkbox-label {
  width: 100px!important;
} */
}

@media (min-width: 641px) {
  .ngx-file-drop__content {
    align-items: center;
    color: #0782d0;
    display: flex;
    height: 100px;
    justify-content: center;
    overflow: hidden !important;
    gap: 1rem;
  }

  .table-responsive {
    overflow: visible;
  }
}


.ngx-file-drop__content {
  gap: 1rem;
}

.border-button {
  border: 1px solid var(--border-outline-darkblue);
  color: var(--border-outline-darkblue);
  background-color: white;
}

.mark-complete-button {
  border: 2px solid var(--bg-transparent);
  font-family: Proxima-Extrabold, sans-serif;
  color: #fff;
  background-color: var(--green-alert-color);
}

.mark-complete-button:focus,
.mark-complete-button:hover {
  border: 2px solid var(--green-alert-color-accent);
  background-color: var(--green-alert-color);
  outline: 0;
}

app-state-search-component .input:focus-visible {
  border: 1px solid var(--border-outline-blue) !important;
  outline: 0;
}

app-state-search-component {
  outline: none 0 !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}

app-city-search .input:focus-visible {
  border: 1px solid var(--border-outline-blue) !important;
  outline: 0;
}

app-city-search {
  outline: none 0 !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}

app-select-component .input:focus-visible {
  border: 1px solid var(--border-outline-blue) !important;
  outline: 0;
}

app-select-component {
  outline: none 0 !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}

app-select-with-search .input:focus-visible {
  border: 1px solid var(--border-outline-blue) !important;
  outline: 0;
}

app-select-with-search {
  outline: none 0 !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  position: static;
}

#dropdownDefaultButton {
  position: relative;
  display: flex;
  align-items: center;
}

#dropdownDefaultButton #dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border-radius: 5px;
  padding: 2px 0;
  border: 1px solid #ccc;
}

#dropdownDefaultButton:hover #dropdown {
  display: block;
}

#dropdownDefaultButton #dropdown li {
  padding: 5px 20px;
}

#dropdownDefaultButton #dropdown li a {
  display: block;
}

.mob-menu-active {
  color: var(--text-orange-color);
}

.mob-menu-active:hover {
  color: var(--text-orange-color);
}

.menu-active .submenu-active {
  color: var(--text-orange-color) !important;
}

.mob-menu-active .submenu-active {
  color: var(--text-orange-color) !important;
}

.active-color {
  /* Text color */
  color: var(--bg-darkblue);
  /* Darkblue */

  /* Cursor style */
  cursor: pointer;
}

.inactive-color {
  /* Text color */
  color: #616264;
  /* gray */
}

.active-grey{
  color: var(--text-grey-color);
}

.active-light-grey {
  color: var(--text-lightgrey-color);
}
.active-dark-grey {
  color: var(--text-darkgrey-color);
}

.active-bg-color {
  /* Background color */
  background-color: var(--bg-darkblue);
  /* Darkblue */

  /* Cursor style */
  cursor: pointer;
}

.inactive-bg-color {
  /* Background color */
  background-color: #616264;
  /* gray */
}

.bg-red-badge {
  background-color: #f53d3d1c;
}

.bg-yellow-badge {
  background-color: #ca8a0433;
}

.text-yellow-badge {
  color: #ca8a04;
}

.text-green-badge {
  color: #48bb78;
}

.bg-green-badge {
  background-color: #48bb783b;
}

@media (max-width: 1024px) {
  #dropdownDefaultButton {
    display: block;
  }

  .menu-downarrow-align {
    display: flex;
    align-items: center;
  }

  #dropdownDefaultButton #dropdown {
    position: relative;
    width: 100%;
  }
}

.recaptcha {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}

.g-recaptcha-bubble-arrow {
  display: none;
}

.g-recaptcha-bubble-arrow + div {
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.z-9999 {
  z-index: 9999 !important;
}


.tooltip__intro-video {
  position: relative;
  display: inline-block;
}

.tooltip__intro-video .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 1rem;
  position: absolute;
  z-index: -11;
  top: -1rem;
  right: 0;
  white-space: nowrap;
  opacity: 0;
}

.tooltip__intro-video .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}

.tooltip__intro-video .tooltiptext {
  visibility: visible;
  opacity: 1;
  right: 5.5rem;
  z-index: 0;
  transition-delay: 5s;
}

.tooltip__intro-video .tooltiptextnone {
  display: none;
}

.custom-noButton-border {
  border: 1px solid var(--border-outline-red) !important;
  color: var(--border-outline-red) !important;
  background-color: white !important;
}

.text-red {
  color: red;
}

.cursor-none {
  pointer-events: none;
}

.sticky {
  top: 50px !important;
  position: sticky;
}

.title-modal {
  position: relative;
}

.scroll-modal {
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  min-height: 50%;
}

@media (max-width: 1920px) {
  .leading-10 .inline {
    display: block;
  }

  .custom-line {
    white-space: nowrap;
  }
}

/* COMMON MODAL START */
.app-common-modal {
  display: none;
}

.app-common-modal .jw-modal {
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: 1000;
}

.app-common-modal .jw-modal .jw-modal-body {
  padding: 20px;
  background: #fff;
  overflow-y: auto;
  /* Height 100% breaks the scroll bar, limit to 99% */
  max-height: 99%;
  overflow-x: hidden;
}


.jw-modal ::-webkit-scrollbar {
  background: #909090;
}

.jw-modal::-webkit-scrollbar-thumb {
  background: #c9c8c8;
}

.app-common-modal .jw-modal-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 900;
}

body.jw-modal-open {
  overflow-y: hidden !important;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.jw-modal h4 {
  font-size: 1.2rem;
}

.jw-modal .title-modal {
  border-bottom: 1px solid #e3eaef;
  padding-bottom: 10px;
}

.new-common-modal .jw-modal .jw-modal-body {
  overflow-y: auto;
  overflow-x: auto;
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 999999999 !important;
}

.fsForm .fsSubmit {
  padding: 0 !important;
}

.ngx-file-drop__content {
  width: 100%;
}

.app-common-modal#view-image-modal .jw-modal-background {
  z-index: 99999;
}

#view-image-modal .jw-modal {
  z-index: 999999;
}

/* COMMON MODAL END */

@media (max-width: 768px) {
  .jw-modal h4 {
    font-size: 1.125rem;
  }
}

.link {
  color: var(--primary);
  cursor: pointer;
}

.link-blue {
  color: var(--dark-blue);
  cursor: pointer;
}

.link:hover, .link-blue:hover {
  text-decoration: underline;
}

.bg-primary-lite {
  background-color: #e044031a
}

.bg-pale-blue {
  background-color: var(--bg-pale-blue);
}

.divide-primary > :not([hidden]) ~ :not([hidden]) {
  border-color: #e04403;
}

.bg-focused-footer {
  background-color: var(--bg-focused-footer)
}

.text-pale-blue {
  color: var(--text-pale-blue)
}

#overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 9999;
}

.center-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.min-h-75 {
  min-height: 75vh;
}
.min-h-25 {
  min-height: 25vh;
}

.section.mb-0 {
  padding-bottom: 1rem;
}
.edit-anytime-modal .jw-modal, .modal-tooltip-zindex .jw-modal {
  z-index: 999999 !important;
}
.edit-anytime-modal .jw-modal-background, .modal-tooltip-zindex .jw-modal-background{
  z-index: 9999 !important;
}
.line-clamp-2 {
   -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
}


@media (min-width: 768px) {
.custom-buttons-flex{
  flex-wrap: wrap !important;
}
}
@media (min-width: 1280px) {
  .custom-buttons-flex{
    flex-wrap: nowrap !important;
}
}
