:root {
  --primary: #e04403;
  --primary-lite:#c73c03;
  --text-secondary: #e7e7e7;
  --bg-transparent: transparent;
  --bg-box: #f8f8f8;
  --dark-blue: #003d85;
  --bg-darkblue: #002b5c;
  --text-blue: #002d61;
  --bg-light-blue: #D9DFE7;
  --text-content: #676767;
  --text-subcontent: #848484;
  --text-accent: #333333;
  --text-accent-consumer: #353535;
  --border-secondary: #b9b9b9;
  --placeholder-grey: #b7b7b7;
  --border-checkbox: #b9b9b9;
  --border-contact-card: #707070;
}

.bg-transparent {
  background-color: transparent;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-dark-blue {
  background-color: var(--bg-darkblue) !important;
}

.bg-box {
  background-color: var(--bg-box);
}

.bg-dark-blue {
  background-color: var(--dark-blue);
}

.bg-light-blue {
  background-color: var(--bg-light-blue);
}

.text-primary {
  color: var(--primary);
}

.border-primary {
  color: var(--primary);
}

.text-secondary {
  color: var(--text-secondary);
}

.text-content {
  color: var(--text-content);
}

.li-disc {
  margin-left: 10px;
  list-style-type: disc;
}

.li-circle {
  margin-left: 10px;
  list-style-type: circle;
}

.text-accent {
  color: var(--text-accent);
}

.text-subcontent {
  color: var(--text-subcontent);
}

.text-blue {
  color: var(--dark-blue);
}

.hover\:text-blue:hover {
  color: var(--dark-blue);
}

.text-consumer-blue {
  color: var(--text-blue);
}

.text-accent-consumer {
  color: var(--text-accent-consumer);
}

.fs-menu {
  font-size: var(--font-size-menu);
  /* line-height: var(--line-height-menu); */
}

.fs-nav-button {
  font-size: var(--font-size-nav-button);
}

.fs-bhead {
  font-size: var(--font-size-banner-head);
  line-height: var(--line-height-head);
}

.fs-bhead b {
  font-size: var(--font-size-banner-head-b);
}
.fs-content {
  font-size: var(--font-size-content);
  line-height: var(--line-height-content);
}

.fs-subcontent {
  font-size: var(--font-size-subcontent);
  line-height: var(--line-height-subcontent);
}

.fs-title {
  font-size: var(--font-size-title);
  line-height: var(--line-height-title);
}

.fs-subtitle {
  font-size: var(--font-size-subtitle);
  line-height: var(--line-height-content);
}

.fs-label {
  font-size: var(--font-size-input-label);
  line-height: var(--line-height-input-label);
}

.lh-menu {
  line-height: var(--line-height-menu);
}

.border-input {
  border-color: var(--border-secondary);
}

::placeholder {
  color: var(--placeholder-grey);
}

.menu--inactive,
.menu--active {
  display: inline-block;
  position: relative;
}

.menu--inactive__consumer,
.menu--active__consumer {
  display: inline-block;
  position: relative;
}

.menu--active {
  color: var(--primary);
}

.menu--active__consumer {
  color: var(--primary);
}

.menu--inactive:hover {
  color: var(--primary);
}

.menu--inactive__consumer:hover {
  color: var(--primary);
}

.menu--inactive:after,
.menu--active::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.menu--inactive__consumer:after,
.menu--active__consumer::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.menu--inactive:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.menu--inactive__consumer:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.menu--active:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.menu--active__consumer:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.border-checkbox {
  border: 1px solid var(--border-checkbox);
}

.b-contact-card {
  border: 1px solid var(--border-contact-card);
  border-radius: 3rem;
}

.org-title {
  font-size: 2rem !important;
  line-height: 2.5rem !important;

}

.org-content {
  font-size: 1.15rem !important;
  line-height: 1.8rem !important;
}

.org-contact-content {
  font-size: 1.75rem !important;
  line-height: 2.8rem !important;
}


@media (max-width:1024px){
  /* .fs-bhead{
    font-size: 2rem !important;
  } */
}
@media (max-width: 575px){
  .org-title {
    font-size: 1.75rem !important;
    line-height: 2.125rem !important;
  }
  .org-content {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
  /* .fs-bhead{
    font-size: 1.7rem !important;
  } */
}
