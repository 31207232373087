:root {
  --font-size-menu: 1.5rem;
  --font-size-nav-button: 1.25rem;
  --font-size-banner-head: 3.5rem;
  --font-size-banner-head-b: 4rem;
  --font-size-content: 1.5rem;
  --font-size-subcontent: 1.25rem;
  --font-size-title: 2.5rem;
  --font-size-subtitle: 2rem;
  --font-size-input-label: 1.25rem;
  --line-height-input-label: 2.375rem;
  --line-height-menu: 3rem;
  --line-height-head: 4rem;
  --line-height-content: 2.25rem;
  --line-height-title: 3rem;
  --line-height-subcontent: 1.938rem;
  --new-landing-banner-head: 42px;
  --new-landing-line-height: 1.2em;
  --box-text: 2rem;
}

.security-box {
  bottom: -100px;
  left: -60px;
  color: #fff;
  position: absolute;
  z-index: 3;
  max-width: 350px;
  height: 265px;
}

.security-img {
  width: 100% !important;
  height: 100% !important;
}

.ml-60 {
  margin-left: 60px;
}

.ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__firstbox {
  position: absolute;
  margin-top: 100px;
  left: -9rem;
  width: 300px;
}

.ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__secondbox {
  position: absolute;
  margin-top: 50px;
  left: -4rem;
  width: 300px;
}

.ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__firstbox {
  position: absolute;
  margin-top: 100px;
  left: -9rem;
  width: 300px;
}

.ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__secondbox {
  position: absolute;
  margin-top: 50px;
  left: -4rem;
  width: 300px;
}

.ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__thirdbox {
  position: absolute;
  margin-top: 50px;
  right: -4.25rem;
  width: 300px;
}

.ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__fourbox {
  position: absolute;
  left: 6rem;
  width: 300px;
  top: 8rem;
}

.ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__thirdbox {
  position: absolute;
  margin-top: 50px;
  right: -4.25rem;
  width: 300px;
}

.ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__fourbox {
  position: absolute;
  left: 6rem;
  width: 300px;
  top: 8rem;
}

.section:nth-child(even) {
  padding: 0;
}

.section:nth-child(odd), .section.section-even {
  padding: 8rem 0;
}

.section.section-bottom {
  padding: 0 0 8rem;
}

.max-w-screen {
  max-width: 1200px;
}

.box__text {
  font-size: var(--box-text);
}

@media (max-width: 1536px) {


  .section:nth-child(even) {
    padding: 0;
  }

  .section:nth-child(odd), .section.section-even {
    padding: 7rem 0;
  }

  .section.section-bottom {
    padding: 0 0 7rem;
  }

  :root {
    --font-size-menu: 1.25rem;
    --font-size-nav-button: 1.1rem;
    --font-size-banner-head: 3rem;
    --font-size-banner-head-b: 3.5rem;
    --line-height-head: 3.5rem;
    --box-text: 1.75rem;
  }

  .ps-timeline-sec .container ol.ps-timeline li {
    height: 70vh;
  }

  .ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__firstbox {
    position: absolute;
    margin-top: 100px;
    left: -9rem;
    width: 260px;
  }

  .ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__secondbox {
    position: absolute;
    margin-top: 50px;
    left: -4rem;
    width: 260px;
  }

  .ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__thirdbox {
    position: absolute;
    margin-top: 50px;
    right: -4.25rem;
    width: 260px;
  }

  .ps-timeline-sec .container ol.ps-timeline li .ps-bot.roadmap__fourbox {
    position: absolute;
    left: 5.8rem;
    width: 260px;
    top: 8rem;
  }

  .feature-banner__wrap,
  .contact-banner__wrap {
    border-bottom-left-radius: 6rem;
    border-bottom-right-radius: 6rem;
  }

  .company-banner__wrap,
  .pricing-banner__wrap {
    border-bottom-left-radius: 6rem;
    border-bottom-right-radius: 6rem;
  }

  .text-accent-inverse {
    color: var(--text-accent);
  }
  .button-onsite-guide {
    padding: 8px 15px;
    line-height: 20px;
    font-size: 18px;
  }
}

@media (max-width: 1440px) {
  .main-logo {
    width: 14rem;
  }
}

@media (max-width: 1280px) {
  .main-logo {
    width: 12rem;
  }

  .max-w-screen {
    max-width: 1140px;
    padding: 0 4rem;
  }

  .security-box {
    max-width: 280px;
    height: 250px;
  }

  :root {
    --font-size-menu: 1.1rem;
    --font-size-nav-button: 1.025rem;
    --font-size-banner-head: 2.5rem;
    --font-size-banner-head-b: 3rem;
    --font-size-content: 1.375rem;
    --line-height-menu: 3.188rem;
    --line-height-head: 3rem;
    --line-height-content: 2.175rem;
    --font-size-input-label: 0.925rem;
    --line-height-input-label: 2.175rem;
    --font-size-subcontent: 1.125rem;
    --font-size-title: 2.325rem;
    --font-size-subtitle: 1.425rem;
    --line-height-title: 3.125rem;
    --line-height-subcontent: 1.8rem;
    --box-text: 1.5rem;
  }

  .button-download {
    padding: 14px 24px;
  }

  .section:nth-child(even) {
    padding: 0;
  }

  .section:nth-child(odd), .section.section-even {
    padding: 6rem 0;
  }

  .section.section-bottom {
    padding: 0 0 6rem;
  }

  .section_x {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  .section_y {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .button-onsite-guide {
    padding: 5px 12px;
}
.button-onsite-guide .avatar-img {
  top: -25px;
}
}

@media (max-width: 1199px) {
  :root {
    --font-size-menu: 1.025rem;
    --font-size-nav-button: 1.025rem;
    --font-size-banner-head: 2.5rem;
    --font-size-banner-head-b: 3rem;
    --font-size-content: 1.375rem;
    --line-height-menu: 2.8rem;
    --line-height-head: 3rem;
    --line-height-content: 2.175rem;
    --font-size-input-label: 0.925rem;
    --line-height-input-label: 2.175rem;
    --font-size-subcontent: 1.125rem;
    --font-size-title: 2.325rem;
    --font-size-subtitle: 1.425rem;
    --line-height-title: 3.125rem;
    --line-height-subcontent: 1.8rem;
  }

  .security-box {
    left: 7%;
    bottom: -125px;
    right: 7%;
    max-width: inherit;
    width: auto;
    height: auto;
  }

  .ml-60 {
    margin-left: 0;
  }

  .max-w-logo {
    width: 15rem;
  }

  .max-w-logo img {
    width: 15rem;
  }

  .custom-logo-large {
    width: 160px !important;
  }

  .button-primary, .button-dark-blue {
    padding: 10px 12px;
  }

  .button-get-started {
    padding: 10px 12px;
  }
  .button-onsite-guide {
    padding: 5px 85px 5px 12px !important;
    font-size: 15px;
    line-height: 17px;
}
.button-onsite-guide .avatar-img {
  top: -27px;
}
}

@media (max-width: 1100px) {
  .max-w-logo {
    width: 12rem;
  }

  .max-w-logo .main-logo {
    width: 12rem;
  }

  .max-w-logo .sponsored-img {
    width: 4rem !important;
  }

  .sponsored-text, .logo-caption {
    font-size: 12px !important;
  }
}

@media (max-width: 1024px) {
  .max-w-screen {
    max-width: 1024px;
    padding: 0 4rem;
  }

  :root {
    --font-size-menu: 1.025rem;
    --font-size-nav-button: 1.025rem;
    --font-size-banner-head: 2rem;
    --font-size-banner-head-b: 2.3rem;
    --font-size-content: 1.25rem;
    --line-height-menu: 2.5rem;
    --line-height-head: 2.825rem;
    --line-height-content: 2rem;
    --font-size-input-label: 0.925rem;
    --line-height-input-label: 2.175rem;
    --font-size-subcontent: 1.2rem;
    --font-size-title: 1.75rem;
    --font-size-subtitle: 1.425rem;
    --line-height-title: 2.5rem;
    --line-height-subcontent: 1.8rem;
    --box-text: 1.25rem;
  }

  /* .button-primary {
    padding: 10px 20px;
  } */
  .button-download {
    padding: 12px 20px;
  }

  .contact-form-banner {
    height: auto;
  }

  .section:nth-child(even) {
    padding: 0;
  }

  .section:nth-child(odd), .section.section-even {
    padding: 5rem 0;
  }

  .section.section-bottom {
    padding: 0 0 5rem;
  }

  .contact-banner__wrap::before {
    height: 95%;
  }

  .focused-banner__wrap .contact-banner__head br {
    display: none;
  }
}

@media (max-width: 767px) {
  :root {
    --font-size-menu: 1.025rem;
    --font-size-nav-button: 0.975rem;
    --font-size-banner-head: 1.75rem;
    --font-size-banner-head-b: 2rem;
    --font-size-content: 1.125rem;
    --line-height-menu: 2.5rem;
    --line-height-head: 2.125rem;
    --line-height-content: 1.875rem;
    --font-size-input-label: 0.825rem;
    --line-height-input-label: 1.975rem;
    --font-size-subcontent: 1.125rem;
    --font-size-title: 1.5rem;
    --font-size-subtitle: 1.425rem;
    --line-height-title: 2.125rem;
    --line-height-subcontent: 1.6rem;
    --box-text: 1rem;
  }

  .max-w-screen {
    max-width: 767px;
    padding: 0 2rem;

  }

  .section_x {
    padding: 2rem;
  }

  .security-img {
    width: 100% !important;
    height: 100% !important;
  }

  .security-box {
    left: 7%;
    right: 7%;
  }

  .b-contact-card {
    border: 1px solid var(--border-contact-card);
    border-radius: 1rem;
  }

  .button-primary, .button-dark-blue {
    padding: 10px 15px;
  }

  .button-get-started {
    padding: 4px 8px;
  }

  .button-download {
    padding: 10px 15px;
  }

  .company-banner__wrap,
  .pricing-banner__wrap,
  .feature-banner__wrap,
  .contact-banner__wrap {
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .testimonial__wrap .h-full {
    height: auto;
  }

  .testimonials-section {
    padding-bottom: 4rem;
  }

  .banner-padding-hfull {
    padding: 100px 0;
  }

  .banner__bg-video {
    border-bottom-left-radius: 4rem;
    border-bottom-right-radius: 4rem;
  }

  .section:nth-child(even) {
    padding: 0;
  }

  .section:nth-child(odd), .section.section-even {
    padding: 4rem 0;
  }

  .section.section-bottom {
    padding: 0 0 4rem;
  }
}

@media (max-width: 640px) {
  .banner__bg-video {
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
  }

  .section:nth-child(even) {
    padding: 0;
  }

  .section:nth-child(odd), .section.section-even {
    padding: 3rem 0;
  }

  .section.section-bottom {
    padding: 0 0 3rem;
  }

  .contact-form .fsSubmitButton {
    padding: 10px 15px;
    font-size: 18px !important;
  }
}

@media (max-width: 540px) {
  :root {
    --font-size-menu: 0.975rem;
    --font-size-nav-button: 0.500rem;
    --font-size-banner-head: 1.625rem;
    --font-size-content: 1rem;
    --line-height-menu: 2.5rem;
    --line-height-head: 2.125rem;
    --line-height-content: 1.5rem;
    --font-size-input-label: 0.825rem;
    --line-height-input-label: 1.975rem;
    --font-size-subcontent: 1rem;
    --font-size-title: 1.5rem;
    --font-size-subtitle: 1.25rem;
    --line-height-title: 2rem;
    --line-height-subcontent: 1.5rem;
  }

  .max-w-logo {
    width: 13rem;
  }

  .max-w-logo img {
    width: 13rem;
  }

  .banner__bg-video {
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }

  .banner-padding-hfull {
    padding: 100px 0;
    height: auto;
    min-height: auto;
  }

  .mh-800-height,
  .mh-700-height,
  .mh-600-height,
  .mh-500-height {
    min-height: auto;
  }
}

@media (max-width: 640px) {
  .custom-header-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    flex-wrap: wrap;
    height: auto;
  }

.button-onsite-guide {
  padding: 5px 65px 5px 12px !important;
  font-size: 14px;
  line-height: 17px;
}
.button-onsite-guide .avatar-img {
  top: -17px;
  width: 80px;
  right: -20px;
}
.play {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 30px;
  bottom: 3px;
}
.play img {
  width: 6px;
  height: 6px;
  margin-left: 2px;
}
}
@media (max-width: 400px) {
  .custom-header-logo {
    flex-wrap: wrap;
    height: auto;
  }
}


@media (max-width: 1024px) {
  /* .bg-content-blue{
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: end;
  } */
  .custom-width {
    margin-right: 0 !important;
  }

  /* .new-landing-height{
    height:600px !important;
  } */
  .focused-banner__wrap, .consumer-marketing-banner__wrap {
    background-position: 30% !important;
  }
}

@media (max-width: 640px) {
  /* .bg-content-blue {
    width: 300px;
  } */
}

@media (max-width: 480px) {
  /* .bg-content-blue {
    width: 230px;
  } */
  .start-now {
    padding: 0.5rem !important;
    margin: 0.7rem 0 !important;
  }

  /* .new-landing-height{
    height:500px !important;
  } */
}

@media (max-width: 1200px) {
  .great-choice-banner {
    height: auto;
    padding: 50px 0;
    min-height: auto;
  }

  .great-choice-banner .max-w-screen.custom-width.mb-0 {
    padding: 0 8rem 0 5rem;
  }
}

@media (max-width: 796px) {
  .max-w-screen.custom-width {
    margin-bottom: 30px !important;
  }

  .max-w-screen.custom-width.mb-0 {
    margin-bottom: 0 !important;
  }

  .max-w-screen.custom-width.mb-0 {
    margin-right: auto !important;
  }

  .section.why-choose-legacynow__wrap.bg-pale-blue {
    padding: 4rem 0 !important;
  }

  .bg-blue-light.pt-4 {
    padding: 25px 0 !important;
  }

  .great-choice-banner {
    height: auto;
    padding: 0 0;
    min-height: auto;
  }

  .great-choice-banner .max-w-screen.custom-width.mb-0 {
    padding: 0 2rem !important;
  }
}

.maxw128 {
  max-width: 8rem !important;
}

@media (max-width: 1440px) {
  .height-400 {
    height: 65vh !important;
  }

  .maxw128 {
    max-width: 6rem !important;
  }
}

@media (max-width: 1100px) {
  .maxw128 {
    max-width: 5rem !important;
  }
}

@media (max-width: 1024px) {
  .height-400 {
    height: 55vh !important;
  }

  .maxw128 {
    max-width: 5rem !important;
  }
}

@media (max-width: 640px) {
  .maxw128 {
    max-width: 5rem !important;
  }
}

@media (max-width: 480px) {
  .great-choice-banner-mobile {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .the-process {
    padding-bottom: 0 !important;
  }

  .maxw128 {
    max-width: 5rem !important;
  }

  .custom-slogan-logo {
    font-size: 10px !important;
  }
}

@media (max-width: 400px) {
  nav .h-20 {
    height: 4rem !important;
  }

  .great-choice-banner-mobile {
    height: 120px;
  }

  .bg-blue-light.pt-4 {
    padding: 20px 0 !important;
  }

  .logo-caption {
    font-size: 11px !important;
  }

  .sponsored-text {
    font-size: 10px !important;
  }

  .maxw128 {
    max-width: 4.5rem !important;
  }
}
