@font-face {
    font-family: Proxima-Regular;
    src: url(../fonts/ProximaNova-Regular.otf);
    font-display: swap;
}

@font-face {
    font-family: Proxima-Thin;
    src: url(../fonts/Proxima-Nova-Thin.otf);
    font-display: swap;
}

@font-face {
    font-family: Proxima-Bold;
    src: url(../fonts/Proxima-Nova-Bold.otf);
    font-display: swap;
}

@font-face {
    font-family: Proxima-Extrabold;
    src: url(../fonts/Proxima-Nova-Extrabold.otf);
    font-display: swap;
}

.text-regular {
    font-family: Proxima-Regular, sans-serif;
}

.text-thin {
    font-family: Proxima-Thin, sans-serif;
}

.text-bold {
    font-family: Proxima-Extrabold, sans-serif;
}

.text-semibold {
    font-family: Proxima-Bold, sans-serif;
}

@font-face {
    font-family: Avenir-Medium;
    src: url(../fonts/avenir/Avenir-Normal.ttf);
    font-display: swap;
}

@font-face {
    font-family: Avenir-Semibold;
    src: url(../fonts/avenir/Avenir-Medium.ttf);
    font-display: swap;
}

@font-face {
    font-family: Avenir-Regular;
    src: url(../fonts/avenir/Avenir-Light.ttf);
    font-display: swap;
}
@font-face {
    font-family: Avenir-Bold;
    src: url(../fonts/avenir/Avenir-bold.ttf);
    font-display: swap;
}
@font-face {
    font-family: Avenir-Black;
    src: url(../fonts/avenir/Avenir-Black.ttf);
    font-display: swap;
}
@font-face {
    font-family: Avenir-BlackOblique;
    src: url(../fonts/avenir/Avenir-BlackOblique.ttf);
    font-display: swap;
}
.font-ave-regular {
  font-family: Avenir-Regular, serif;
}
.font-ave-medium {
     font-family: Avenir-Medium, serif;
}
.font-ave-semibold {
     font-family: Avenir-Semibold, serif;
}
.font-ave-bold {
     font-family: Avenir-Bold, serif;
}
.font-ave-black {
    font-family: Avenir-Black, serif;
}
.font-ave-black-oblique {
    font-family: Avenir-BlackOblique, serif;
}

@font-face {
    font-family: MinionPro-Regular;
    src: url(../fonts/MinionPro-Regular.otf) format("opentype");
    font-display: swap;
}

.font-mini-regular {
  font-family: MinionPro-Regular, serif;
}
